import React from "react"
import Seo from "../../components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import paths from "../../constants/paths"
import styled from "styled-components"

const Container = styled.div`
  article {
    background: var(--brand-color-yellow);
    text-align: center;
    padding: 1rem;

    &:first-child {
      background: var(--brand-color-green);
    }

    a {
      text-decoration: none;

      h2 {
        text-transform: uppercase;
        color: var(--brand-color-white);
        margin: 0;
        padding-bottom: 0;
      }
    }
  }

  .gatsby-image-wrapper, img {
    max-height: calc(100vh-79px);
  }
`;

const Portfolio = ({ location }) => {
  return (
    <Container>
      <Seo title={paths.portfolio.title} location={location} />
      <div className="pure-g">
        <article className="pure-u-1-2 pure-u">
          <Link to={paths.portfolioWeddings.url}>
            <StaticImage alt="elopement-snoqualmie-pass-waterfall-goldcreek-pond-washington-seattle-photographer-wedding-6" src="../../images-galleries/portfolios/home/07_Hurricane-Ridge-Adventure-Wedding-Olympic-National-Park-Elopement-Photographer-13.jpg" width={1440}/>
            <h2>View Weddings</h2>
          </Link>
        </article>
        <article className="pure-u-1-2 pure-u">
          <Link to={paths.portfolioCouples.url}>
            <StaticImage alt="Seattle Adventure Wedding Photographer" src="../../images-galleries/portfolios/home/11_North-Cascades-Artist-Point-Elopement-Photographer-PNW-Weddings-2.jpg" width={1440}/>
            <h2>View Couples</h2>
          </Link>
        </article>
      </div>
    </Container>
  )
}

export default Portfolio
